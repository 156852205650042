function actuallyTabs() {
 let filterButton = document.querySelectorAll('.type');
 let wrapperFilters = document.querySelector('.sssources-list')
 let moreBlogs = document.querySelector('.show-more-news');
 let selectedClass;

 if(!filterButton.length || !wrapperFilters) {
  return
 }

 wrapperFilters.querySelector('LI').classList.add('active')

 for (let i = 0; i < filterButton.length; i++) {
  filterButton[i].addEventListener('click', function(e) {
   e.preventDefault();
   selectedClass = filterButton[i].getAttribute('data-type');

   filterButton.forEach(button => {
    button.classList.remove('active')
   })

   filterButton[i].classList.add('active')
   console.log(selectedClass)

   if(selectedClass === 'all') {
    wrapperFilters.querySelector('LI').classList.add('active')
   } else {
    wrapperFilters.querySelector(`.` + selectedClass).classList.add('active')
   }
  }) 
 }

 if(wrapperFilters.querySelectorAll('LI').length < 4) {
  moreBlogs.style.display = 'none'
 }
 
 console.log('Tabs start')
}
actuallyTabs()