function menuIcon() {
 let menuButton = document.querySelector(".menu_icon");
 let menuContainer = document.querySelector(".header")

 if (!menuButton || !menuContainer) {
  return
 }

 menuButton.addEventListener("click", function (e) {
  e.preventDefault();
  menuContainer.classList.toggle("active")
 })

 window.addEventListener('scroll', function () {
  if (window.scrollY >= 100) {
   menuContainer.classList.add("fixed")
  } else {
   menuContainer.classList.remove("fixed")
  }
 })
}
menuIcon()