function slider() {
 if(!document.querySelector('.slide')) {
  return
 }

 document.querySelector('.slide').classList.add('active')
 console.log('Slider Loaded');

 let sliderContainer = document.querySelector('.product_container');
 let productBox = document.querySelector('.product-box');
 let productTabs = document.querySelectorAll('.product_tabs');
 let navBox = document.querySelector('.slides');
 let dataId = document.querySelectorAll('.slide');
 let itemWidth = (document.querySelector('.slide').offsetWidth);
 let sliderContainerWidth = (document.querySelector('.products-slider .title-bar').offsetWidth);
 let dataElement;
 let stopSlide = false;
 if (!sliderContainer) {
  return
 }

 function moveSlide() {
  productBox.style.transform = `translateX(${-100 * dataElement}%)`
 }

 function autoPlay() {
  dataElement = 0
  setInterval(function () {
   dataElement = dataElement + 1
   if (dataElement >= productTabs.length) {
    dataElement = 0
   }

   moveSlide()

   var moveWidth = 0
   var totalWidth = 0;
   var checkWidthElement = dataElement - 1;
   if(checkWidthElement < 0){
    checkWidthElement = 0
   }
   dataId.forEach((element, item) => {
    element.classList.remove('active')
    if(item < checkWidthElement){
     moveWidth += element.offsetWidth
    }
    totalWidth += element.offsetWidth
   });
   if((totalWidth - moveWidth) > sliderContainerWidth){
    navBox.style.transform = `translateX(${-moveWidth}px)`
   }
   else{
    navBox.style.transform = `translateX(${-(totalWidth-sliderContainerWidth)}px)`
   }

   dataId[dataElement].classList.add('active')

  }, 6000)
 }
 autoPlay()

 for (let i = 0; i < dataId.length; i++) {
  dataId[i].addEventListener('click', function (e) {
   e.preventDefault();

   dataId.forEach(element => {
    element.classList.remove('active')
   })
   dataId[i].classList.add('active')

   dataElement = Number(dataId[i].getAttribute('data-index'))
   moveSlide()
  })
  dataId[i].addEventListener('click', function (e) {
   e.preventDefault();

   dataId.forEach(element => {
    element.classList.remove('active')
   })
   dataId[i].classList.add('active')

   dataElement = Number(dataId[i].getAttribute('data-index'))

   var moveWidth = 0
   var totalWidth = 0;
   var checkWidthElement = dataElement - 1;
   if(checkWidthElement < 0){
    checkWidthElement = 0
   }
   dataId.forEach((element, item) => {
    if(item < checkWidthElement){
     moveWidth += element.offsetWidth
    }
    totalWidth += element.offsetWidth
   });
   if((totalWidth - moveWidth) > sliderContainerWidth){
    navBox.style.transform = `translateX(${-moveWidth}px)`
   }
   else{
    navBox.style.transform = `translateX(${-(totalWidth-sliderContainerWidth)}px)`
   }

  })
 }
}
slider()