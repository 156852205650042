function subscribeFunction() {
 let buttonSubscribe = document.querySelector('#subscribe-window-btn');
 let wrapperSubscribe = document.querySelector('#subscribe-window-display');
 let buttonSubscribeClosed = document.querySelector('#subscribe-close-btn');
 if (!buttonSubscribe) {
  return
 }

 buttonSubscribe.addEventListener('click', function (e) {
  e.preventDefault();
  wrapperSubscribe.classList.add('active');
 })

 if (!buttonSubscribeClosed) {
  return
 }

 buttonSubscribeClosed.addEventListener('click', function (e) {
  e.preventDefault();
  wrapperSubscribe.classList.remove('active');
 })
}
subscribeFunction()